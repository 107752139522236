

require('../styles/index.scss');

import 'bootstrap';
import 'owl.carousel';
import 'particlesjs';
import 'wow.js';

//common js should be imported here
import 'common/js/header.js';

//page specific js should be imported here
import './home.js';

// // require('normalize.css/normalize.css');
// require('../styles/index.scss');

// document.addEventListener("DOMContentLoaded", () => {

//     const pluginsTriggerElement = document.getElementById('plugins-trigger');
//     const pluginsElement = document.getElementById('plugins');

//     const pluginsVisibleClass = "splash-overview-plugins__list--visible";

//     pluginsTriggerElement.onclick = () => {
//         pluginsElement.classList.toggle(pluginsVisibleClass);
//     }
// });


