

// $(document).ready(event => {
//     const $buttons = $("#side-nav [data-action='open'], #side-nav [data-action='close']");
//     const $menu = $("#side-nav div.menu");
//     $buttons.click(e => $menu.toggleClass('open'));
// });








$(document).ready(function() {
    // executes when HTML-Document is loaded and DOM is ready
   
    $('.second-button').on('click', function () {

      $('.animated-icon2').toggleClass('open');
      $('body').toggleClass('menu-open');
      });


   // breakpoint and up  
   $(window).resize(function(){
       if ($(window).width() >= 980){	
   
         // when you hover a toggle show its dropdown menu
         $(".navbar .dropdown-toggle").hover(function () {
            $(this).parent().toggleClass("show");
            $(this).parent().find(".dropdown-menu").toggleClass("show"); 
          });
   
           // hide the menu when the mouse leaves the dropdown
         $( ".navbar .dropdown-menu" ).mouseleave(function() {
           $(this).removeClass("show");  
         });
     
           // do something here
       }	
   });  
     
     
   
   // document ready  
   });




   $(window).scroll(function () {
    // console.log($(window).scrollTop())
    if ($(window).scrollTop() > 63) {
      $('.navbar').addClass('navbar-fixed');
      $('.navbar-brand  img').attr('src', '/assets/logo2.png');
      
      
    }
    if ($(window).scrollTop() < 64) {
      $('.navbar').removeClass('navbar-fixed');
      $('.navbar-brand  img').attr('src', '/assets/logo2.png');
      // $('.navbar').addClass('navbar-bg');
      // $('.navbar').removeClass('navbar-bg');
    }
  });


  //  $(window).scroll(function () {
  //   // console.log($(window).scrollTop())
  //   if ($(window).scrollTop() > 63) {
  //     $('.navbar').addClass('navbar-fixed');
  //     $('.navbar-brand  img').attr('src', '/assets/logo1.png');
      
      
  //   }
  //   if ($(window).scrollTop() < 64) {
  //     $('.navbar').removeClass('navbar-fixed');
  //     $('.navbar-brand  img').attr('src', '/assets/logo2.png');
  //     // $('.navbar').addClass('navbar-bg');
  //     // $('.navbar').removeClass('navbar-bg');
  //   }
  // });


 

  $(document).ready(function() {
    new WOW().init();
    });



    const image1 = document.querySelector('.image1')
const image2 = document.querySelector('.image2')
const topTitle = document.querySelector('.top-title')

const mutate = (pct) => {
  image2.style.setProperty('--moveY', (1 - pct) * 5);
  image2.style.setProperty('--scale', 1.25 - pct / 4);
  image2.style.setProperty('--opacity', pct);
  topTitle.style.setProperty('--moveY', `${(1 - pct) * (1 - pct) * 80}vh`);
  topTitle.style.setProperty('--scale', `${(pct / 2 + .5)}`);
};

let last_known_scroll_position = 0;
let ticking = false;

function doSomething(scroll_pos) {
  // Do something with the scroll position
  mutate(1 - scroll_pos / window.innerHeight);
}

window.addEventListener('scroll', function(e) {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      doSomething(last_known_scroll_position);
      ticking = false;
    });

    ticking = true;
  }
});

    const images = Array.from(document.querySelectorAll('.image'))
    // images.forEach((image) => {
    //   image.setAttribute(
    //     'src', 'data:image/jpg;base64,
    //   );
    // })



    